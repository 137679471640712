<template>
  <Layout v-load="loading">
    <div class="d-sm-flex justify-content-between mb-4 mt-3">
      <h3 class="mb-4 mb-sm-0">Activity Log</h3>
    </div>
    <div class="card">
        <div class="row">
          <div class="col-12">
            <b-table hover :items="list" :fields="fields" stacked="md" id="account-table">
              <template #cell(created_at)="data">
                {{ $moment(data.item.created_at).format('DD/MM/YYYY') }}
              </template>
              <template #cell(causer)="data">
                {{ data.item.causer.name }}
              </template>
              <template #cell(propertiesNew)="data">
                <div v-if="getPropertiesNew(data.item.properties)">
                  <div v-for="(property, key) in getPropertiesNew(data.item.properties)" :key="key">
                    <strong>{{ key }}</strong> : {{ property }}
                  </div>
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <template #cell(propertiesOld)="data">
                <div v-if="getPropertiesOld(data.item.properties)">
                  <div v-for="(property, key) in getPropertiesOld(data.item.properties)" :key="key">
                    <strong>{{ key }}</strong> : {{ property }}
                  </div>
                </div>
                <div v-else>
                  -
                </div>
              </template>
            </b-table>
          </div>
          <div class="pagination">
            <div class="pagination__per-page">
              <span>Per page:</span>
              <select id="perPage" class="form-control" v-model="pageLimit">
                <option
                    v-for="limit in pageLimits"
                    :key="limit"
                    :value="limit"
                >
                  {{ limit }}
                </option>
              </select>
            </div>
            <div class="pagination__page-number">
              <b-pagination
                  v-if="totalRows > pageLimit"
                  align="right"
                  v-model="page"
                  :per-page="pageLimit"
                  :total-rows="totalRows"
              />
            </div>
          </div>
        </div>
      </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main.vue';

export default {
  name: 'ActivityLog',
  components: {
    Layout
  },
  data() {
    return {
      fields: [
        {
          key: 'created_at',
          label: 'Date',
          sort: true,
          sortDesc: false,
          style: {width: '150px'}
        },
        {key: 'causer', label: 'Employee'},
        {key: 'description', label: 'Event'},
        {key: 'propertiesNew', label: 'New'},
        {key: 'propertiesOld', label: 'Old'}
      ],
      page: 1,
      pageLimit: 20,
      pageLimits: [20, 40, 60, 80, 100],
      firstPaginationLoad: false
    }
  },
  async mounted() {
    this.firstPaginationLoad = true
    this.setPaginationConfig()
    await this.$store.dispatch('activityLog/getActivityLog', this.queryParams)
    this.firstPaginationLoad = false
  },
  watch: {
    pageLimit(val) {
      if (val && !this.firstPaginationLoad) {
        this.$store.dispatch('activityLog/getActivityLog', this.queryParams);
        this.$store.commit('activityLog/SET_PAGINATION_SETTING', {page: this.page, limit: val})
        this.$router.push(`?page=${this.page}&limit=${val}`).catch(() => false)
      }
    },
    page(val) {
      if (val && !this.firstPaginationLoad) {
        this.$store.dispatch('activityLog/getActivityLog', this.queryParams);
        this.$store.commit('activityLog/SET_PAGINATION_SETTING', {page: val, limit: this.pageLimit})
        this.$router.push(`?page=${val}&limit=${this.pageLimit}`).catch(() => false)
      }
    }
  },
  methods: {
    getPropertiesNew(properties) {
      return JSON.parse(properties).attributes
    },
    getPropertiesOld(properties) {
      return JSON.parse(properties).old
    },
    setPaginationConfig() {
      const {page, limit} = this.$route.query;
      if (page && limit) {
        this.page = +page + 1 ? +page : 1;
        this.pageLimit = this.pageLimits.includes(+limit) ? +limit : this.pageLimits[0];
      }
    },
  },
  computed: {
    list() {
      return this.$store.state.activityLog.list;
    },
    loading() {
      return this.$store.state.activityLog.loading;
    },
    queryParams() {
      return {
        page: this.page,
        per_page: this.pageLimit
      }
    },
    totalRows() {
      return this.$store.state.activityLog.totalItems;
    },
  }
}
</script>
